@import '../../marketplace.css';

.logoMobile {
  width: auto;
  height: 54px;

  & path {
    fill: var(--marketplaceColor);
  }
}
