@import '../../marketplace.css';

.root {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.address {
  margin-top: 0;
  margin-bottom: 20px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.email {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.phoneNumber {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.firmName {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.websiteUrl {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 16px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    color: var(--matterColor);
  }
}
