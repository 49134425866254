@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.pageSubheading {
  text-align: center;
  color: red;
  margin-top: 30px;
  font-weight: 400;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: contain;
  margin: 32px 0 0;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 100%;
}

.contentSubtitle {
  font-style: italic;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 32px;
  margin-bottom: 16px;
}

.diskList {
  list-style-type: disc;
  padding-left: 40px;
  margin-block-start: 0;
}
